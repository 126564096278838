.about {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  margin: 100px 0 100px 0;
  scroll-margin-top: 100px;
}

@media (max-width: 1200px) {
  .about {
    font-size: large;
  }
}

.statement {
  margin: 20px;
}

@media (min-width: 700px) {
  .statement {
    width: 60%;
  }
}

.contact-me {
  margin-top: 50px;
}
