.myprojects {
  /* height: 100vh;
  width: 100vw; */
  margin: 100px 0 100px 0;
  scroll-margin-top: 100px;
  scroll-margin-bottom: 100px;
}

.myprojects-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: x-large;
  align-items: center;
}
@media only screen and (max-width: 1200px) {
  .myprojects-container {
    font-size: large;
  }
}

.project-anchor {
  margin: 20px;
  font-size: 3vw;
}

@media screen and (max-width: 700px) {
  .project-anchor {
    font-size: 4vw;
  }
}

.title {
  margin: 20px 20px 50px 20px;
}
