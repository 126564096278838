body {
  background-size: cover;
  background-repeat: no-repeat;
  background: url("./assets/Images/abstractPurple-2880x1800.jpeg") fixed center
    80%;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 700px) {
  body {
    background: #41295a; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to left,
      #2f0743,
      #41295a
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to left,
      #2f0743,
      #41295a
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}

a {
  color: #fff;
  all: unset;
}

a,
a:visited {
  color: #fff;
}

a:hover {
  color: blueviolet;
  cursor: pointer;
}
